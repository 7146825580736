import React, { useState } from "react"
import RequestAcceess from "./RequestAccess"
import Modal from "../elements/Modal"

import addToMailchimp from "gatsby-plugin-mailchimp"

import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"

import { useSelector, useDispatch } from "react-redux"
import { setShowRequestAccessWindow } from "../../state/actions"

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Index = props => {
  const dispatch = useDispatch()

  const showRequestAccessWindow = useSelector(
    state => state.showRequestAccessWindow
  )

  const location = props.location !== undefined ? props.location : {}
  const pathname = location.pathname !== undefined ? location.pathname : ""

  const isRuPath = pathname.indexOf("/ru") !== -1

  const [openSuccess, setOpenSuccess] = useState(false)
  const [openError, setOpenError] = useState(false)

  const closeRequestAccessWindow = (event, reason) => {
    dispatch(setShowRequestAccessWindow(false))
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    setOpenSuccess(false)
    setOpenError(false)
  }

  const onRequestAccess = async data => {
    const userEmail = data["EMAIL"]
    if (data.SUBSCRIBE_NEWS === true) {
      data["group[45177][1]"] = 1
    }
    delete data.SUBSCRIBE_NEWS

    try {
      const result = await addToMailchimp(userEmail, data)
      if (result.result === "success") {
        setOpenSuccess(true)
      }
      if (result.result === "error") {
        setOpenError(true)
        console.error(result.msg)
      }
      closeRequestAccessWindow()
    } catch (error) {
      setOpenError(true)
      console.error(error.toString())
    }
  }

  return (
    <>
      <Modal
        show={showRequestAccessWindow}
        handleClose={closeRequestAccessWindow}
      >
        <RequestAcceess
          submitCallback={onRequestAccess}
          lang={isRuPath === true ? "ru" : "en"}
        />
      </Modal>

      <Snackbar
        open={openSuccess}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="success">
          Thank you for subscribing!
        </Alert>
      </Snackbar>

      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={handleClose} severity="error">
          Error!
        </Alert>
      </Snackbar>
    </>
  )
}

export default Index
