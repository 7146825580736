import React from "react"
import Header from "../components/structure/Header"
import Footer from "../components/structure/Footer"
import RequestAccessWindow from "../components/layout/RequestAccessWindow"
import { Helmet } from "react-helmet"

const LayoutDefault = props => (
  <div className="application">
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="description"
        content="Wizart DCC Platform Open Source* extensible 3D editor based on Pixar's Universal Scene Description (USD)"
      />
      <meta name="application-name" content="Wizart DCC Platform"/>
      <meta charset="utf-8"/>
      <meta name="keywords"content="Wizart DCC,platform,framework,USD,Universal Scene Description,python,Qt,UI,open source,standalone,animation,Pixar,pxr,computer,graphics,edit,editor,usda,usdc,usdz,film,vr,ar,xr,mobile,usdview,how do I edit usd files,hair,hair system,digital avatars,avatars,free,3D,lighting,shading,lookdev,scene assembly,set dressing,layout,sculpting,curves,haircut,instances,instancing,variants,cloth,blender,cycles,rendering,render,rigging,setup,character rigging,control rig,USD Editor,USDZ editor,hydra, render delegate,usdrender,node editor,industry standard,artist friendly,manipulators"/>
      <title>Wizart DCC Platform</title>
      <link rel="canonical" href="https://wizartsoft.com" />
    </Helmet>
    <div className={"body-wrap"}>
      <Header {...props} navPosition="right" className="reveal-from-bottom" />
      <main className="site-content">{props.children}</main>
      <Footer {...props} />
    </div>
    <RequestAccessWindow {...props} />
  </div>
)

export default LayoutDefault
