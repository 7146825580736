import React from "react"
import FormLabel from "../../elements/FormLabel"
import Button from "../../elements/Button"
import ButtonGroup from "../../elements/ButtonGroup"
import { useForm } from "react-hook-form"
import ClassNames from "classnames"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import "./index.css"

const fieldsNames = {
  en: {
    HEADER: "REQUEST ACCESS",
    FNAME: "First name",
    LNAME: "Last name",
    FCOMPANY: "Company",
    EMAIL: "Email",
    FMESSAGE: "Message (Optional)",
    SUBMIT: "Submit",
    SUBSCRIBE_NEWS: "Subscribe to Newsletter",
  },
  ru: {
    HEADER: "ЗАПРОСИТЬ ДОСТУП",
    FNAME: "Имя",
    LNAME: "Фамилия",
    FCOMPANY: "Компания",
    EMAIL: "Email",
    FMESSAGE: "Сообщение (Необязательно)",
    SUBMIT: "Отправить",
    SUBSCRIBE_NEWS: "Подписаться на новости",
  },
}

const schema = yup
  .object({
    FNAME: yup
      .string()
      .required("First name is a required field")
      .min(2)
      .max(20),
    LNAME: yup
      .string()
      .required("Last name is a required field")
      .min(2)
      .max(20),
    FCOMPANY: yup
      .string()
      .required("Company is a required field")
      .min(2)
      .max(20),
    EMAIL: yup.string().required("Email is a required field").email(),
    FMESSAGE: yup.string(),
    SUBSCRIBE_NEWS: yup.boolean(),
  })
  .required()

const ErrorMessage = props => {
  return (
    <p className={ClassNames("required-message", "text-color-error")}>
      {props.children}
    </p>
  )
}

const errorColor = "#FF6171"

const Index = props => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  })

  const onSubmit = data => {
    props.submitCallback(data)
  }

  const labelStyle = {
    fontSize: 16,
    color: "white",
  }

  const labels = fieldsNames[props.lang]

  return (
    <>
      <div className={"center-content"}>
        <h3
          style={{
            marginTop: 0,
          }}
        >
          {labels.HEADER}
        </h3>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormLabel style={labelStyle}>{labels.FNAME}</FormLabel>
        <input
          className={"form-input"}
          {...register("FNAME")}
          style={{ borderColor: errors.FNAME && errorColor }}
        />
        {errors.FNAME && <ErrorMessage>{errors.FNAME.message}</ErrorMessage>}
        <FormLabel style={labelStyle}>{labels.LNAME}</FormLabel>
        <input
          className={"form-input"}
          {...register("LNAME")}
          style={{ borderColor: errors.LNAME && errorColor }}
        />
        {errors.LNAME && <ErrorMessage>{errors.LNAME.message}</ErrorMessage>}
        <FormLabel style={labelStyle}>{labels.FCOMPANY}</FormLabel>
        <input
          className={"form-input"}
          {...register("FCOMPANY")}
          style={{ borderColor: errors.FCOMPANY && errorColor }}
        />
        {errors.FCOMPANY && (
          <ErrorMessage>{errors.FCOMPANY.message}</ErrorMessage>
        )}
        <FormLabel style={labelStyle}>{labels.EMAIL}</FormLabel>
        <input
          className={"form-input"}
          {...register("EMAIL")}
          style={{ borderColor: errors.EMAIL && errorColor }}
        />
        {errors.EMAIL && <ErrorMessage>{errors.EMAIL.message}</ErrorMessage>}
        <FormLabel style={labelStyle}>{labels.FMESSAGE}</FormLabel>
        <textarea
          className={"form-input"}
          {...register("FMESSAGE")}
          style={{
            borderColor: errors.FMESSAGE && errorColor,
            marginBottom: 3,
          }}
        />
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          <label className={"form-checkbox subscribe-form-checkbox"}>
            <input
              type="checkbox"
              {...register("SUBSCRIBE_NEWS")}
              value={true}
              className={"subscribe-checkbox"}
              defaultChecked={true}
            />
            <FormLabel style={labelStyle}>{labels.SUBSCRIBE_NEWS}</FormLabel>
          </label>
        </div>
        <div className={"center-content"}>
          <ButtonGroup
            style={{
              marginTop: 0,
            }}
          >
            <Button type="submit" wideMobile color={"primary"}>
              {labels.SUBMIT}
            </Button>
          </ButtonGroup>
        </div>
      </form>
    </>
  )
}

export default Index
