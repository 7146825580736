import React from "react"
import Button from "../elements/Button"

import { useDispatch } from "react-redux"
import { setShowRequestAccessWindow } from "../../state/actions"

const Index = ({ name, openCallback, ...props }) => {
  const dispatch = useDispatch()

  const clickOpenRequestAccessButton = () => {
    dispatch(setShowRequestAccessWindow(true))
    if (openCallback !== false) {
      openCallback()
    }
  }

  return (
    <>
      <Button
        tag="a"
        color="primary"
        wideMobile
        onClick={event => clickOpenRequestAccessButton(event)}
        {...props}
      >
        {name}
      </Button>
    </>
  )
}

Index.defaultProps = {
  openCallback: false,
}

export default Index
