import React from "react"
import Image from "../../elements/Image"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const LogoSmallText = props => {
  return (
    <div
      className="m-0"
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {props.children}
    </div>
  )
}

const LogoText = props => {
  return (
    <h5
      className="m-0"
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      {props.children}
    </h5>
  )
}

const Logo = ({ className, footerMode, ruPath, ...props }) => {
  const iconSize = footerMode === true ? 24 : 32
  const LogoTextComponent = footerMode === true ? LogoSmallText : LogoText

  return (
    <Link to={ruPath === true ? "/ru/" : "/"}>
      <div {...props}>
        <LogoTextComponent>
          <div className="m-0">WIZART DCC PLATFORM</div>
        </LogoTextComponent>
      </div>
    </Link>
  )
}

Logo.propTypes = {
  footerMode: PropTypes.bool,
  ruPath: PropTypes.bool,
}

Logo.defaultProps = {
  footerMode: false,
  ruPath: false,
}

export default Logo
