import React from "react"
import classNames from "classnames"
import { Link } from "gatsby"

const FooterNav = ({ className, ...props }) => {
  const classes = classNames("footer-nav", className)

  const location = props.location !== undefined ? props.location : {}
  const pathname = location.pathname !== undefined ? location.pathname : ""

  const isRuPath = pathname.indexOf("/ru") !== -1
  const path = isRuPath === true ? "/ru" : "/en"

  const textAbout = isRuPath === true ? "О компании" : "About"
  const textFAQ = isRuPath === true ? "Вопросы" : "FAQ"
  const textContact = isRuPath === true ? "Написать нам" : "Contact"

  return (
    <nav className={classes}>
      <ul className="list-reset">
        <li>
          <Link
            to="#"
            onClick={e => {
              window.location = "mailto:hello@wizartsoft.com"
              e.preventDefault()
            }}
          >
            {textContact}
          </Link>
        </li>
        <li>
          <Link to={`${path}/about/`}>{textAbout}</Link>
        </li>
        <li>
          <Link to={`${path}/faq/`}>{textFAQ}</Link>
        </li>
      </ul>
    </nav>
  )
}

export default FooterNav
