import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Logo from "./partials/Logo"
import LanguageSelectorMenu from "./LanguageSelectors/LanguageSelectorMenu"
import SolutionsMenu from "./Solutions/SolutionsMenu"
import RightNavMenu from "./RightNavMenu/RightNavMenu"
import { Link } from "gatsby"
import { useMediaQuery, IconButton, Box } from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import RequestAccessButton from "../../components/layout/RequestAccessButton"
import ButtonGroup from "../../components/elements/ButtonGroup"

const propTypes = {
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
}

const defaultProps = {
  navPosition: "",
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
}

const Header = ({
  className,
  navPosition,
  hideNav,
  hideSignin,
  bottomOuterDivider,
  bottomDivider,
  ...props
}) => {
  const [showSolutionsMenu, setShowSolutionsMenu] = useState(false)
  const [showRightNavMenu, setShowRightNavMenu] = useState(false)
  const matches = useMediaQuery("(min-width:640px)")

  const classes = classNames(
    "site-header",
    bottomOuterDivider && "has-bottom-divider",
    className
  )

  const location = props.location !== undefined ? props.location : {}
  const pathname = location.pathname !== undefined ? location.pathname : ""

  const isRuPath = pathname.indexOf("/ru") !== -1
  const path = isRuPath === true ? "/ru" : "/en"

  const textAbout = isRuPath === true ? "О компании" : "About"
  const textFAQ = isRuPath === true ? "Вопросы" : "FAQ"

  const textSolutions = isRuPath === true ? "Решения" : "Solutions"

  const requestAccessText =
    isRuPath === true ? "Запросить доступ" : "Request Access"

  const rightNavMenuProps = {
    pathname,
    path,
    isRuPath,
    textAbout,
    textFAQ,
    textSolutions,
    requestAccessText
  }

  const clickSolutionLink = event => {
    event.preventDefault()
    setShowSolutionsMenu(!showSolutionsMenu)
  }

  const hoverOnSolution = event => {
    setShowSolutionsMenu(true)
  }

  const hoverOffSolution = event => {
    setShowSolutionsMenu(false)
  }

  useEffect(() => {
    if (matches === false) {
      setShowRightNavMenu(false)
    }
  }, [matches])

  return (
    <>
      <header className={classes} style={{ zIndex: 2 }}>
        <div className="container">
          <div
            className={classNames(
              "site-header-inner",
              bottomDivider && "has-bottom-divider"
            )}
          >
            <Logo ruPath={isRuPath} />
            {matches ? (
              <nav className={classNames("header-nav")}>
                <div className="header-nav-inner">
                  <ul
                    className={classNames(
                      "list-reset text-xs",
                      navPosition && `header-nav-left`
                    )}
                  >
                    <li onMouseEnter={e => hoverOnSolution(e)}>
                      <a
                        className={
                          showSolutionsMenu === true ? "bold-text-nav" : ""
                        }
                        href={""}
                        onClick={e => clickSolutionLink(e)}
                      >
                        {textSolutions}
                      </a>
                    </li>
                  </ul>
                  <ul
                    className={classNames(
                      "list-reset text-xs",
                      navPosition && `header-nav-right`
                    )}
                  >
                    <li>
                      <Link to={`${path}/about/`}>{textAbout}</Link>
                    </li>
                    <li>
                      <Link to={`${path}/faq/`}>{textFAQ}</Link>
                    </li>
                  </ul>
                  {/* <LanguageSelectorMenu {...props} /> */}
                  <ButtonGroup>
                    <RequestAccessButton name={requestAccessText} size={"sm"} />
                  </ButtonGroup>
                </div>
              </nav>
            ) : (
              <IconButton
                className={classNames("right-menu-button")}
                onClick={() => setShowRightNavMenu(!showRightNavMenu)}
              >
                {showRightNavMenu ? (
                  <HighlightOffIcon
                    className={classNames("right-menu-button")}
                  />
                ) : (
                  <MenuIcon className={classNames("right-menu-button")} />
                )}
              </IconButton>
            )}
          </div>
        </div>
      </header>

      {!matches ? (
        <RightNavMenu
          {...props}
          {...rightNavMenuProps}
          show={showRightNavMenu}
          setShow={value => setShowRightNavMenu(value)}
        />
      ) : (
        false
      )}

      <header
        className={classes}
        style={{
          top: 80,
          backgroundColor: "#151719",
          display: showSolutionsMenu === true ? "block" : "none",
        }}
        onMouseLeave={e => hoverOffSolution(e)}
      >
        <SolutionsMenu show={showSolutionsMenu} path={path} hasTopDivider />
      </header>
    </>
  )
}

Header.propTypes = propTypes
Header.defaultProps = defaultProps

export default Header
