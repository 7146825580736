import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import {
  Drawer,
  List,
  ListItem,
  Divider,
  Typography,
  ClickAwayListener,
} from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"

const propTypes = {
  showSubMenu: PropTypes.bool,
  closeSubMenu: PropTypes.func,
  closeMenu: PropTypes.func,
  isRuPath: PropTypes.bool,
  keyProp: PropTypes.string,
}

const defaultProps = {
  showSubMenu: false,
  closeSubMenu: () => {},
  closeMenu: () => {},
  isRuPath: false,
  keyProp: "",
}

const SubMenu = props => {
  const { showSubMenu, closeSubMenu, closeMenu, isRuPath, keyProp } = props

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent={false}
      open={showSubMenu}
      onClickAway={() => {
        if (showSubMenu) {
          closeMenu()
        }
      }}
      key={keyProp}
    >
      <Drawer
        variant="persistent"
        className={`right-menu`}
        anchor="right"
        open={showSubMenu}
      >
        <List style={{ marginBottom: 0 }}>
          <ListItem button onClick={closeSubMenu}>
            <ArrowBackIcon
              className={classNames("right-menu-button")}
              style={{ marginRight: isRuPath ? 45 : 55 }}
            />
            <Typography
              className={classNames("right-menu-button")}
              align="center"
              variant="h6"
            >
              {isRuPath ? "Назад" : "Back"}
            </Typography>
          </ListItem>
        </List>

        <Divider className="right-menu-divider" />

        {props.children}
      </Drawer>
    </ClickAwayListener>
  )
}

SubMenu.propTypes = propTypes
SubMenu.defaultProps = defaultProps

export default SubMenu
