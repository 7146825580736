const getSolutionsData = path => {
  const isRuPath = path.indexOf("/ru") !== -1

  const data = [
    {
      name: isRuPath === true ? "VFX/Анимация" : "VFX/Animation",
      items: [
        {
          name: isRuPath === true ? "USD Пайплайн" : "USD Pipeline",
          path: `${path}/solutions/usd_pipeline`,
        },
        {
          name: isRuPath === true ? "Сборка Сцен" : "Scene Assembly",
          path: `${path}/solutions/scene_assembly`,
        },
        {
          name: isRuPath === true ? "Создание Волос" : "Hair",
          path: `${path}/solutions/hair`,
        },
        // {
        //   name: isRuPath === true ? "Шейдинг" : "Lookdev",
        //   path: `${path}/solutions/lookdev`,
        // },
        // {
        //   name: isRuPath === true ? "Освещение" : "Lighting",
        //   path: `${path}/solutions/lighting`,
        // },
        {
          name: isRuPath === true ? "Blender Пайплайн" : "Blender Pipeline",
          path: `${path}/solutions/blender_pipeline`,
        },
      ],
    },
    // {
    //   name: "VR/AR",
    //   items: [
    //     {
    //       name: isRuPath === true ? "Цифровые Аватары" : "Digital Avatars",
    //       path: `${path}/solutions/digital_avatars`,
    //     },
    //     {
    //       name: isRuPath === true ? "Мобильный AR" : "Mobile AR",
    //       path: `${path}/solutions/mobile_ar`,
    //     },
    //   ],
    // },
  ]

  return data
}

export default getSolutionsData
