import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import {
  Drawer,
  Divider,
  List,
  ListItem,
  ClickAwayListener,
} from "@material-ui/core"
import LanguageSelectorSubMenu from "../LanguageSelectors/LanguageSelectorSubMenu"
import SubMenu from "./SubMenu"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import SolutionsSubMenu from "../Solutions/SolutionsSubMenu"
import RequestAccessButton from "../../layout/RequestAccessButton"

const propTypes = {
  navPosition: PropTypes.string,
}

const defaultProps = {
  navPosition: "",
}

function noScroll() {
  window.scrollTo(0, 0)
}

const Header = ({ navPosition, ...props }) => {
  const {
    show,
    setShow,
    pathname,
    path,
    isRuPath,
    textAbout,
    textFAQ,
    textSolutions,
    requestAccessText,
  } = props

  const [showLanguageSubMenu, setShowLanguageSubMenu] = useState(false)
  const [showSolutionsSubMenu, setShowSolutionsSubMenu] = useState(false)

  const closeSubMenu = () => {
    setShowLanguageSubMenu(false)
    setShowSolutionsSubMenu(false)
  }

  const closeMenu = () => {
    closeSubMenu()
    setShow(false)
  }

  const awayClickClose = () => {
    if (show && !(showLanguageSubMenu || showSolutionsSubMenu)) {
      closeMenu()
    }
  }

  useEffect(() => {
    if (show) {
      window.addEventListener("scroll", noScroll)
    } else {
      window.removeEventListener("scroll", noScroll)
      closeMenu()
    }
  }, [show])

  return (
    <>
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent={false}
        open={show}
        onClickAway={() => {
          if (show) {
            awayClickClose()
          }
        }}
      >
        <Drawer
          variant="persistent"
          className={"right-menu"}
          anchor="right"
          open={show}
        >
          <List className="right-menu-list">
            <Divider className="right-menu-divider" />

            <ListItem button onClick={() => setShowSolutionsSubMenu(true)}>
              <div className="right-menu-listItem-container">
                <Link
                  className={"right-menu-linkButton"}
                  to={pathname}
                  onClick={event => event.preventDefault()}
                >
                  {textSolutions}
                </Link>
                <ArrowForwardIcon className={"right-menu-button"} />
              </div>
            </ListItem>

            <Divider className="right-menu-divider" />

            <ListItem button>
              <Link
                className="right-menu-linkButton"
                to={`${path}/about/`}
                onClick={closeMenu}
              >
                {textAbout}
              </Link>
            </ListItem>

            <Divider className="right-menu-divider" />

            <ListItem button>
              <Link
                className="right-menu-linkButton"
                to={`${path}/faq/`}
                onClick={closeMenu}
              >
                {textFAQ}
              </Link>
            </ListItem>

            <Divider className="right-menu-divider" />

            {/* <ListItem button onClick={() => setShowLanguageSubMenu(true)}>
              <div className="right-menu-listItem-container">
                <Link
                  className={"right-menu-linkButton"}
                  to={pathname}
                  onClick={event => event.preventDefault()}
                >
                  {pathname.includes("/ru/")
                    ? "Изменить язык"
                    : "Change language"}
                </Link>
                <ArrowForwardIcon className={"right-menu-button"} />
              </div>
            </ListItem> */}

            <Divider className="right-menu-divider" />

            <ListItem>
              <RequestAccessButton
                name={requestAccessText}
                openCallback={closeMenu}
              />
            </ListItem>
          </List>
        </Drawer>
      </ClickAwayListener>

      {/* <SubMenu
        showSubMenu={showLanguageSubMenu}
        closeSubMenu={() => setShowLanguageSubMenu(false)}
        closeMenu={closeMenu}
        isRuPath={isRuPath}
        keyProp={"Language"}
      >
        <LanguageSelectorSubMenu pathname={pathname} />
      </SubMenu> */}

      <SubMenu
        showSubMenu={showSolutionsSubMenu}
        closeSubMenu={() => setShowSolutionsSubMenu(false)}
        closeMenu={closeMenu}
        isRuPath={isRuPath}
        keyProp={"Solutions"}
      >
        <SolutionsSubMenu path={path} />
      </SubMenu>
    </>
  )
}

Header.propTypes = propTypes
Header.defaultProps = defaultProps

export default Header
