import React from "react"
import classNames from "classnames"
import { Link } from "gatsby"

const Index = props => {
  const data = props.data
  const items = data.items

  return (
    <div className={classNames("solution-menu-item", "center-content-mobile")}>
      <div style={{ textAlign: "center" }}>
        <h6
          style={{
            marginTop: 20,
            marginBottom: 0,
            color: "#9ca9b3",
          }}
        >
          {data.name}
        </h6>
      </div>
      <ul className={classNames("list-reset text-xs")}>
        {items.map((value, index) => (
          <li key={index}>
            <Link to={`${value.path}`}>
              <h5
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                {value.name}
              </h5>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Index
