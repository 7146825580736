import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import MenuColumn from "./MenuColumn"
import getSolutionsData from "./getSolutionsData"

const propTypes = {
  show: PropTypes.bool,
}

const defaultProps = {
  show: false,
}

const Index = ({ show, hasTopDivider, ...props }) => {
  const path = props.path

  const columnsData = getSolutionsData(path)

  return (
    <div
      className={classNames("container-xs", hasTopDivider && "has-top-divider")}
    >
      <div className={"solution-menu"}>
        {columnsData.map((value, index) => (
          <MenuColumn data={value} key={index} />
        ))}
      </div>
    </div>
  )
}

Index.propTypes = propTypes
Index.defaultProps = defaultProps

export default Index
