import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Logo from "./partials/Logo"
import FooterNav from "./partials/FooterNav"
// import FooterSocial from "./partials/FooterSocial"
import LanguageSelectorMenu from "./LanguageSelectors/LanguageSelectorMenu"

const propTypes = {
  topOuterDivider: PropTypes.bool,
  topDivider: PropTypes.bool,
}

const defaultProps = {
  topOuterDivider: false,
  topDivider: false,
}

const Footer = ({ className, topOuterDivider, topDivider, ...props }) => {
  const classes = classNames(
    "site-footer center-content-mobile",
    topOuterDivider && "has-top-divider",
    className
  )

  const location = props.location !== undefined ? props.location : {}
  const pathname = location.pathname !== undefined ? location.pathname : ""

  const isRuPath = pathname.indexOf("/ru") !== -1

  return (
    <footer className={classes}>
      <div className="container">
        <div className={classNames("site-footer-inner", "has-top-divider")}>
          <div className="footer-top space-between text-xxs">
            <Logo footerMode={true} ruPath={isRuPath} />
            {/* <FooterSocial /> */}
          </div>
          <div className="footer-bottom space-between text-xxs invert-order-desktop">
            <FooterNav {...props} />
            <nav className={"header-nav"}>
              <div>
                {/* <LanguageSelectorMenu {...props} /> */}
              </div>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = propTypes
Footer.defaultProps = defaultProps

export default Footer
