import React from "react"
import { Link } from "gatsby"
import { List, ListItem, Divider, Typography } from "@material-ui/core"
import classNames from "classnames"
import PropTypes from "prop-types"
import getSolutionsData from "./getSolutionsData"

const propTypes = {
  path: PropTypes.string,
}

const defaultProps = {
  path: "",
}

const LanguageSelectorSubMenu = props => {
  const { path } = props
  const solutionsData = getSolutionsData(path)

  const generateElements = (data, useDivider = false) => {
    return (
      <>
        <List
          style={{
            marginBottom: 0,
          }}
          subheader={
            <Typography
              style={{
                margin: "5px 0px 5px 0px",
                color: "#9ca9b3",
              }}
              id={data.name}
              align="center"
              variant="h6"
            >
              {" "}
              {data.name}
            </Typography>
          }
        >
          {data.items.map(item => {
            return (
              <ListItem button key={item.name}>
                <Link
                  className={classNames("right-menu-linkButton")}
                  to={`${item.path}`}
                >
                  {item.name}
                </Link>
              </ListItem>
            )
          })}
        </List>
        {useDivider ? <Divider className="right-menu-divider" /> : false}
      </>
    )
  }

  return (
    <>
      {solutionsData.map((item, index) => {
        return generateElements(
          item,
          index !== Object.keys(solutionsData).length - 1
        )
      })}
    </>
  )
}

LanguageSelectorSubMenu.propTypes = propTypes
LanguageSelectorSubMenu.defaultProps = defaultProps

export default LanguageSelectorSubMenu
